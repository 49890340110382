import React, { useEffect } from "react";
import { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import styled from "styled-components";
import { Parallax } from "react-scroll-parallax";

import HomePage from "./home-page";
import SolutionsPage from "./solutions-page";
import PortfolioPage from "./portfolio-page";
import BlogPage from "./blog-page";
import Header from "./header";
import SideMenu from "./side-menu";
import Footer from "./footer";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export default function App(props) {
  return (
    <AppWrapper>
      <Header />
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/home" exact element={<HomePage />} />
          {/* <Route path="/solutions" exact element={<SolutionsPage />} /> */}
          <Route path="/portfolio" exact element={<PortfolioPage />} />
          {/* <Route path="/blog*" element={<BlogPage />} /> */}
        </Routes>
        <Footer />
      <SideMenu />
    </AppWrapper>
  );
};
