import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 64px;
  width: 100%;
  margin-left: 0px;
  @media (min-width: 900px) {
    margin-top: 100px;
    margin-left: 100px;
    margin-left: 100px;
    font-size: 18px;
    width: 500px;
  }
  @media (min-width: 1200px) {
    width: 700px;
  }
`;

const Caption = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  color: #27272C;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  color: #27272C;
  margin-bottom: 16px;
`;

const ImageWrapper = styled.div`
  padding: 32px;
  margin-bottom: 32px;
  background: white;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
`;

const Plot = (props) => {

  const { title, caption, file, link } = props;

  return (
    <Wrapper>
      <ImageWrapper href={link}>
        <Image src={file} />
      </ImageWrapper>
      <Title>
        {title}
      </Title>
      <Caption>
        {caption}
      </Caption>
    </Wrapper>
  );
};

export default Plot;
