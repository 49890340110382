import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import BlogCard from "./blog-card";
import BlogPost from "./blog-post";
import Chip from "./chip";
import Page from "./page";
import { BannerBlock } from "./block-components";

const content =
  [
    {
      type: "heading",
      text: "Section heading",
    },
    {
      type: "paragraph",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      type: "code",
      text: `# Extract the year of the case
case_text |>
  str_extract("C-[0-9]+/[0-9]+") |> # Extract the case number
  str_extract("[0-9]+$") |> # Extract the last number in the case number
  as.numeric() # Convert the string to an intenger
`,
    },
    {
      type: "paragraph",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      type: "image",
      file: "example-blog-image.png",
    },
    {
      type: "heading",
      text: "Section heading",
    },
    {
      type: "paragraph",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
  ]

const data = [
  {
    id: 1,
    link: "/blog/post-1",
    title: "Regular expressions in Python and R",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    author: "Josh Fjelstul, PhD",
    author_title: "Founder, J. C. Fjelstul Consulting LLC",
    date: "October 10, 2023",
    banner: "example-blog-banner.png",
    tags: [
      "Data science", "Python", "Machine learning", "Neural networks", "Natural language processing",
    ],
    content: content
  },
  {
    id: 2,
    link: "/blog/post-2",
    title: "Training deep convolutional neural networks for text",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    author: "Josh Fjelstul, PhD",
    author_title: "Founder, J. C. Fjelstul Consulting LLC",
    date: "October 10, 2023",
    tags: [
      "Data science", "Python", "Machine learning", "Neural networks",
    ],
    content: content
  },
  {
    id: 3,
    link: "/blog/post-3",
    title: "Training deep convolutional neural networks for text",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    author: "Josh Fjelstul, PhD",
    author_title: "Founder, J. C. Fjelstul Consulting LLC",
    date: "October 10, 2023",
    tags: [
      "Data science", "Python", "Machine learning", "Neural networks",
    ],
    content: content
  },
];

const TitleWrapper = styled.div`
  box-sizing: border-box;
  padding-top: 125px;
  padding-bottom: 125px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 50px;
  background-color: #57C1F6;
  width: 100%;
  @media (min-width: 900px) {
    padding-left: 100px;
    padding-right: 100px;
  }
`;

const Title = styled.div`
  font-size: 60px;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.2;
  color: #ffffff;
  @media (min-width: 900px) {
    font-size: 128px;
  }
`;

const CardWrapper = styled.div`
  box-sizing: border-box;
  margin-bottom: 100px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  @media (min-width: 900px) {
    padding-left: 100px;
    padding-right: 0px;
  }
`;

const ChipRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 48px;
  @media (min-width: 900px) {
    margin-left: 100px;
    margin-right: 0px;
    width: 650px;
  }
  @media (min-width: 1200px) {
    margin-left: 100px;
    margin-right: 0px;
    width: 900px;
  }
`;

const BlogPage = (props) => {

  const blogPage =
    <Page>
      <BannerBlock text={<div>Data <br /> science <br /> blog</div>} />
      {/* <ChipRow>
        <Chip>
          Data science
        </Chip>
        <Chip>
          Machine learning
        </Chip>
        <Chip>
          Statistics
        </Chip>
        <Chip>
          Data viz
        </Chip>
        <Chip>
          R programming
        </Chip>
        <Chip>
          Python programming
        </Chip>
      </ChipRow> */}
      <CardWrapper>
        {data.map((item, i) => {
          return (
            <BlogCard key={i} data={item} light={i % 2 === 0 ? true : false} />
          )
        })}
      </CardWrapper>
    </Page>

  return (
    <Routes>
      <Route path="/" exact element={blogPage} />
      {data.map((item, i) => (
        <Route key={i} path={item.link.replace("/blog/", "")} exact element={<BlogPost data={item} cardData={data} />} />
      ))}
    </Routes>
  )
}

export default BlogPage;
