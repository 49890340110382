import React, { useEffect } from "react";
import styled from "styled-components";
import { Block, BlockTitle } from "./block-components";

const randomize = (array) => {

  let currentIndex = array.length;
  let randomIndex;

  while (currentIndex > 0) {

    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

let data = [
  { type: "programming", label: "dplyr" },
  { type: "programming", label: "ggplot2" },
  { type: "programming", label: "stringr" },
  { type: "programming", label: "lubridate" },
  { type: "programming", label: "purrr" },
  { type: "programming", label: "forcats" },
  { type: "programming", label: "ggiraph" },
  { type: "programming", label: "ggraph" },
  { type: "programming", label: "shiny" },
  { type: "programming", label: "tidytext" },
  { type: "programming", label: "tidymodels" },
  { type: "programming", label: "scikit-learn" },
  { type: "programming", label: "NumPy" },
  { type: "programming", label: "TensorFlow" },
  { type: "statistics", label: "Linear regression" },
  { type: "statistics", label: "Logistic regression" },
  { type: "statistics", label: "Multinomial logistic regression" },
  { type: "statistics", label: "Ordinal probit regression" },
  { type: "statistics", label: "Multi-level models" },
  { type: "statistics", label: "Fixed effects" },
  { type: "statistics", label: "Random effects" },
  { type: "statistics", label: "Difference-in-differences" },
  { type: "statistics", label: "Structural equation models" },
  { type: "statistics", label: "Stationarity" },
  { type: "statistics", label: "Random walks" },
  { type: "machine learning", label: "Random forests" },
  { type: "machine learning", label: "XGBoost" },
  { type: "machine learning", label: "Decision trees" },
  { type: "machine learning", label: "Ensemble methods" },
  { type: "machine learning", label: "Neural networks" },
  { type: "machine learning", label: "Convolutional neural networks" },
  { type: "machine learning", label: "Recurrent neural networks" },
  { type: "machine learning", label: "Transformers" },
  { type: "machine learning", label: "Attention models" },
  { type: "machine learning", label: "Reinforcement learning" },
  { type: "machine learning", label: "K-means clustering" },
  { type: "machine learning", label: "Principal component analysis" },
  { type: "machine learning", label: "Recommender systems" },
  { type: "machine learning", label: "Gradient descent" },
  { type: "full-stack development", label: "React.js" },
  { type: "full-stack development", label: "Express.js" },
  { type: "full-stack development", label: "Node.js" },
  { type: "full-stack development", label: "NGINX" },
  { type: "full-stack development", label: "MongoDB" },
  { type: "full-stack development", label: "SQL" },
  { type: "full-stack development", label: "REST APIs" },
  { type: "full-stack development", label: "JSON" },
  { type: "full-stack development", label: "HTTP requests" },
  { type: "full-stack development", label: "Axios" },
  { type: "full-stack development", label: "JavaScript" },
  { type: "full-stack development", label: "JSX" },
  { type: "full-stack development", label: "styled-components" },
];

const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 100px;
`;

const Tab = styled.div`
  font-size: 28px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 16px;
  padding-bottom: 4px;
  padding-left: 2px;
  padding-right: 2px;
  cursor: pointer;
  transition: 0.3s;
  opacity: ${props => props.selected ? "1" : "0.3"};
  border-bottom: ${props => props.selected ? "1px solid #ffffff" : "1px solid #ffffff00"};
  &:hover {
    opacity: 1;
  }
`;

data = randomize(data);

const SkillWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1200px;
`;

const Skill = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
  opacity: ${props => props.selected ? "1" : "0.3"};
  margin-right: 32px;
  margin-bottom: 24px;
  transition: 0.3s;
`;

const Wrapper = styled.div`
  width: 100%;
  display: none;
  @media (min-width: 900px) {
    display: flex;
  }
`;

const SkillsSection = (props) => {

  const [type, setType] = React.useState("machine learning");

  return (
    <Wrapper>
      <Block theme="dark">
        <BlockTitle>
          My skills.
        </BlockTitle>
        <TabWrapper>
          <Tab selected={type === "machine learning"} onMouseOver={() => setType("machine learning")}>
            Machine learning
          </Tab>
          <Tab selected={type === "statistics"} onMouseOver={() => setType("statistics")}>
            Statistics and causal inference
          </Tab>
          <Tab selected={type === "full-stack development"} onMouseOver={() => setType("full-stack development")}>
            Full-stack web app development
          </Tab>
          <Tab selected={type === "programming"} onMouseOver={() => setType("programming")}>
            Programming
          </Tab>
        </TabWrapper>
        <SkillWrapper>
          {data.map((item, i) => {
            return (
              <Skill key={i} selected={item.type === type}>
                {item.label}
              </Skill>
            )
          })}
        </SkillWrapper>
      </Block>
    </Wrapper>
  )
}

export default SkillsSection;
