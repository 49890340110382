import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "./button";

const HeaderWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 128px;
  padding-bottom: 32px;
  width: 100%;
  @media (min-width: 900px) {
    padding-left: 200px;
    padding-right: calc(100px - 32px);
    padding-top: 48px;
    padding-bottom: 48px;
    justify-content: space-between;
  }
`;

const HeaderLogoWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const HeaderLogo = styled.img`
  height: 70px;
  @media (min-width: 900px) {
    height: 96px;
  }
  @media (min-width: 1200px) {
    height: 128px;
  }
`;

const HeaderWordMark = styled.img`
  height: 72px;
  margin-left: 8px;
  @media (min-width: 900px) {
    height: 72px;
    margin-top: 8px;
  }
  @media (min-width: 1200px) {
    margin-top: 12px;
    height: 96px;
  }
`;

const ButtonWrapper = styled.div`
  display: none;
  @media (min-width: 900px) {
    display: flex;
  }
`;

const Header = () => {
  return (
    <HeaderWrapper>
      <HeaderLogoWrapper to="/" >
        <HeaderLogo src="/logo.svg" />
        <HeaderWordMark src="/word-mark.svg" />
      </HeaderLogoWrapper>
      <ButtonWrapper>
        <Button as="a" href="mailto:joshfjelstul@gmail.com">
          Set up a meeting.
        </Button>
      </ButtonWrapper>
    </HeaderWrapper>
  );
}

export default Header;
