import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: unset;
  width: unset;
  height: 100px;
  padding-left: 50px;
  padding-top: 8px;
  background-color: #ffffff;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  @media (min-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: unset;
    width: 100px;
    height: unset;
    padding-left: 0px;
    padding-top: 0px;
    border: none;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
  }
`;

const Drawer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  z-index: 1100;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-left: 50px;
  background-color: #57C1F6;
  transition: opacity 0.3s ease-in-out;
  opacity: ${props => props.open ? "1" : "0"};
  overflow-x: hidden;
  pointer-events: ${props => props.open ? "unset" : "none"};
  @media (min-width: 900px) {
    padding-left: 200px;
  }
`;

const Item = styled(Link)`
  font-size: 40px;
  font-weight: 200;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 4px;
  margin-top: 18px;
  margin-bottom: 18px;
  padding-bottom: 2px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border-bottom: solid 3px #ffffff00;
  &:hover {
    border-bottom: solid 3px #ffffff;
  }
  @media (min-width: 900px) {
    font-size: 80px;
  }
`;

const Menu = (props) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  return (
    <Wrapper>
      <div id="nav-icon3" className={menuOpen ? "open" : "close"} onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <Drawer open={menuOpen}>
        <Item to="/" onClick={() => setMenuOpen(false)} >
          Home
        </Item>
        {/* <Item to="/solutions" onClick={() => setMenuOpen(false)} >
          Solutions
        </Item> */}
        <Item to="/portfolio" onClick={() => setMenuOpen(false)} >
          Portfolio
        </Item>
        {/* <Item to="/blog" onClick={() => setMenuOpen(false)} >
          Blog
        </Item> */}
      </Drawer>
    </Wrapper>
  );
}

export default Menu;
