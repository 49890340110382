import React, { useEffect } from "react";
import styled from "styled-components";
import { Block, BlockTitle, BlockHeading, BlockParagraph, BlockButtonWrapper } from "./block-components";
import { Feature, NumberedFeature, FeatureList } from "./feature-components";
import SkillsSection from "./skills-section";
import Button from "./button";
import Page from "./page";

const LandingTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 50px;
  line-height: 1.3;
  color: #F7F7F7;
  width: 100%;
  @media (min-width: 900px) {
    margin-left: 100px;
    font-size: 42px;
    width: 600px;
  }
  @media (min-width: 1200px) {
    font-size: 48px;
    width: 800px;
  }
`;

//--------------------------------------------------
// home page
//--------------------------------------------------

const HomePage = () => {
  return (
    <Page>
      <Block theme="dark">
        <LandingTitle>
          Leverage data science to tackle uncertainty.
        </LandingTitle>
        <BlockParagraph>
          I help startups, small businesses, non-profits, and advocacy groups use data science, artificial intelligence (AI), and machine learning (ML) to solve complex problems and boost their potential. I'm based in Austin, Texas.
        </BlockParagraph>
        <BlockButtonWrapper>
          <Button as="a" href="mailto:joshfjelstul@gmail.com" inverted={true}>
            Let's talk.
          </Button>
        </BlockButtonWrapper>
      </Block>
      <Block theme="white">
        <BlockTitle>
          How I can help.
        </BlockTitle>
        <BlockParagraph>
          Nearly any organization — no matter its size or mission — can use data science to make better decisions. And many organizations can use artificial intelligtence (AI) and machine learning (ML) — critical data science tools — to develop better, more personalized products and services.
        </BlockParagraph>
        <BlockParagraph>
          Whether you already know what kind of data science solution you need, and need help implementing it, or you're just starting to think about how data science — including AI and ML — can help your organization, I can help you design and implement an effective data science strategy.
        </BlockParagraph>
        <FeatureList>
          <Feature
            title="Machine learning."
            subtitle={null}
            text="Do you want to offer your customers a more personalized experience? Automate a repetitive, time-consuming task? Optimize a complex business process? I can help you develop and implement custom ML models to learn consumer preferences and recommend personalized content, uncover new market segments and strategically engage customers, learn and predict consumer behavior to boost conversion and churn rates, tag and classify text (emails, reviews, posts), analyze the sentiment of consumer reviews and social media posts, moderate user-generated text, detect anomolies and fraud, and more."
            file="/icons/learning-1.svg"
            variant="vertical"
            width={350}
          />
          <Feature
            title="Forecasting."
            subtitle={null}
            text="How will your organization's key performance indicators (KPIs) change over time under different scenarios? I can help you tackle uncertainty and plan for the future by developing statistical forecasting models that can predict KPIs into the future and Monte Carlo simulations that can predict KPIs under a variety of possible scenarios."
            file="/icons/prediction.svg"
            variant="vertical"
            width={350}
          />
          <Feature
            title="Causal inference."
            subtitle={null}
            text="Do you want to test how a new product feature or design choice will impact key performance indicators (KPIs) before you do a full roll-out? Or do you want to know how effective a new policy or program is? I can help you design an implementation strategy that creates an opportunity to use causal inference techniques to estimate the causal effect of a product feature, design choice, policy, or program on the KPIs you care about — so you can optimize your organizaton's performance and demonstrate that what you're doing actually works."
            file="/icons/evaluation.svg"
            variant="vertical"
            width={350}
          />
          <Feature
            title="Data visualization."
            subtitle={null}
            text="Good data visualization can be a game-changer for your business, non-profit, or advocacy group. I can collaborate with you to create publication-quality data visualizations or to develop intuitive, interactive data visualization tools that will help your organization better communicate a story, showcase your successes, or make more informed decisions."
            file="/icons/plot-1.svg"
            variant="vertical"
            width={350}
          />
        </FeatureList>
        <BlockParagraph>
          Have a project in mind? Get in touch and let's talk about how I can help you make it happen. I'm also available for data science consulting at an hourly or daily rate if you need advice on an ongoing project.
        </BlockParagraph>
        <BlockButtonWrapper>
          <Button as="a" href="mailto:joshfjelstul@gmail.com" inverted={false}>
            Let's talk.
          </Button>
        </BlockButtonWrapper>
      </Block>
      <Block theme="dark">
        <BlockTitle>
          Who I work with.
        </BlockTitle>
        <BlockParagraph>
          I work with startups, small businesses, non-profits, and advocacy groups who need data science solutions — including AI and ML solutions — but don't have the budget for an in-house data science team.
        </BlockParagraph>
        <BlockParagraph>
          Let's say you want to use ML in a product. Developing a data pipeline, training an ML model, and deploying an ML-powered product is expensive and technically complex — especially for smaller organizations. I can help you develop and implement an effective strategy for using data science to address the challenges your organization is facing. And it'll be much easier and cheaper than trying to do it in-house.
        </BlockParagraph>
        <BlockButtonWrapper>
          <Button as="a" href="mailto:joshfjelstul@gmail.com" inverted={true}>
            Let's talk.
          </Button>
        </BlockButtonWrapper>
      </Block>
      <Block color="white">
        <BlockTitle>
          Data science? AI? ML?
        </BlockTitle>
        <BlockParagraph>
          Data science, artificial intelligence (AI), and machine learning (ML) and are all closely related, but they're not the same thing. There's one thing they all have in common, though — and that's data. Here's what you need to know.
        </BlockParagraph>
        <FeatureList>
          <Feature
            title="Data science."
            subtitle={null}
            text="Data science is about extracting insights from data and using those insights to make better decisions. Data scientists use many tools, including statistics, artificial intelligence (AI), machine learning (ML), and data visualization to uncover these insights. Think of AI and ML as tools. Data science is the process of using these tools to solve problems."
            file="/icons/workflow-1.svg"
            variant="vertical"
            width={300}
          />
          <Feature
            title="Artificial intelligence."
            subtitle={null}
            text="Artificial intelligence (AI) is about using technology to build machines that can do tasks usually associated with human inelligence — things like processing and generating language (speech and text) and images. AI algorithms are tools that data scientists can use to solve problems. ML algorithms are one class of AI algorithm. AI is an over-used buzzword. Most of the time, what people really mean is ML."
            file="/icons/ai-4.svg"
            variant="vertical"
            width={"400px"}
          />
          <Feature
            title="Machine learning."
            subtitle={null}
            text="Machine learning (ML) is about creating algorithms that allow machines to learn from data autonomously, without being explicitely programmed, and using those algorithms to make predictions. ML heavily overlaps with statistics. Deep learning, computer vision, natural language processing (NLP) — that's all ML. ML is a branch of AI. It's one of the many tools that data scientists use to solve problems."
            file="/icons/learning-2.svg"
            variant="vertical"
            width={300}
          />
        </FeatureList>
      </Block>
      <SkillsSection />
      <Block theme="light">
        <BlockTitle>
          Boost your team.
        </BlockTitle>
        <BlockParagraph>
          Data science, AI, and ML are now critical to success in nearly every industry. You may not have the budget to hire in-house data scientists or ML engineers, but that doesn't mean you can't still stay ahead of the AI curve.
        </BlockParagraph>
        <BlockParagraph>
          I've trained everyone from students who've never written a line of code before to industry-leading researchers how to incorporate data science and machine learning skills into their workflow. I can teach your team the skills they'll need to tackle your organization's toughest challenges.
        </BlockParagraph>
        <BlockParagraph>
          If you're looking for tips and tutorials, check out my blog posts and my articles on Medium. I'll be sharing lots of useful tips I've picked up working on projects.
        </BlockParagraph>
        <BlockParagraph>
          If you want more in-depth training, get in touch and we can discuss options for one-on-one training or options to arrange an interactive, online workshop that's tailored to your organization's needs.
        </BlockParagraph>
        <BlockButtonWrapper>
          <Button as="a" href="mailto:joshfjelstul@gmail.com" inverted={false}>
            Let's talk.
          </Button>
        </BlockButtonWrapper>
      </Block>
      <Block color="white">
        <BlockTitle>
          Why hire me?
        </BlockTitle>
        <BlockParagraph>
          You don't want to trust your data science strategy or your data — one of your organization's most valuable assets — to just anyone. Here's why I'm the right choice to help your organization.
        </BlockParagraph>
        <FeatureList>
          <NumberedFeature
            number={1}
            title="I can design and implement end-to-end data science and machine learning projects."
            text="To do an end-to-end ML project in-house, you'd typically have to hire — at minimum — a product manager, a data scientist, an ML engineer, a backend developer, and a frontend developer. I'm a data scientist, an ML engineer, and a full-stack web developer, so I can cover all of these rolls. I can develop a data pipeline, create a production-ready ML model, develop an API to get ML predictions where you need them, and create an intuitive, interactive web app to interface with an API. This means I can develop a high-quality, well-integrated solution for your organization at a small faction of the cost of doing it in-house."
            width="65%"
          />
          <NumberedFeature
            number={2}
            title="I understand how to apply data science and machine learning to solve real business problems."
            text="It's one thing to have the technical skills to train ML models. It's another to figure out how to creatively use ML to solve real-world business problems — and my world-class business training helps me do that. I have a BBA in Business Honors from the Canfield Business Honors Program (CBHP), an innovative and accelerated MBA-style BBA program at the McCombs School of Business at The University of Texas at Austin, a top-10 business school."
            width="65%"
          />
          <NumberedFeature
            number={3}
            title="I have a 10+ year track-record of producing high-quality machine learning and data science work."
            text="My data science work has been featured by leading outlets, featured by leading newspapers, news services, and blogs around the world, including FiveThirtyEight, The Markup, the Washington Post, the Times, Hindustan Times, and Agence France-Presse (AP). I've also published 10+ original quantitative research articles that rely on machine learning and data science tools in top international journals."
            width="65%"
          />
          <NumberedFeature
            number={4}
            title="I have world-class training in data science, artificial intelligence, and machine learning."
            text="I have a PhD in political science with a specialization in quantitative methods from Emory University, one of the top quantitative social science programs in the country. But ML tools are constantly advancing, and I'm always improving my skills and learning new methods. I have a professional certificate in machine learning (ML) from Stanford University and DeepLearning.AI (a leading AI education technology company), and I'm completing certificates in deep learning and natural language processing (NLP) from DeepLearning.AI. You can be confident that I can deliver on a project."
            width="65%"
          />
          <NumberedFeature
            number={5}
            title="I have extensive experience developing and executing complex research designs."
            text="The problems that startups and small businesses face often require innovative solutions. I can leverage my extensive experience conducting end-to-end research to develop a research design to tackle your problem. I've conducted quantitative research at leading universities — Emory University, Washington University in St. Louis, the University of Geneva, and the University of Oslo — and I've published industry-leading quantitative research in top international journals."
            width="65%"
          />
          <NumberedFeature
            number={6}
            title="I can communicate the key takeaways from complex analyses to non-technical audiences."
            text="I can clearly explain in non-technical language what I'm doing, how it works, and why it's the right solution for your organization. And I can help you explain to your customers, clients, shareholders, or investors how we're solving your most challenging business problems — and what you're doing to stay ahead of the competition."
            width="65%"
          />
        </FeatureList>
        <BlockParagraph>
          Ready to get started? Let's schedule a meeting to talk about your business problem and how we can leverage data science, artificial intelligence, and machine learning to solve it.
        </BlockParagraph>
        <BlockButtonWrapper>
          <Button as="a" href="mailto:joshfjelstul@gmail.com">
            Let's talk.
          </Button>
        </BlockButtonWrapper>
      </Block>
      <Block theme="light">
        <BlockTitle>
          About me.
        </BlockTitle>
        <BlockParagraph>
          Hey! I'm Josh, and I'm the founder of J. C. Fjelstul Consulting LLC. I'm a data scientist, machine learning engineer, and computational social scientist. I use data science to help startups, small businesses, non-profits, and advocacy groups solve challenging problems.
        </BlockParagraph>
        <BlockParagraph>
          I have a 10+ year track-record of conducting end-to-end, industry-leading quantitative research using cutting-edge data science, artificial intelligence, machine learning tools.
        </BlockParagraph>
        <BlockParagraph>
          I have extensive experience developing research designs, designing and building complex databases, conducting causal inference studies, and using data visualization to communicate key takeaways to diverse audiences.
        </BlockParagraph>
        <BlockParagraph>
          My data science work has been featured by leading newspapers, news services, and blogs around the world, including FiveThirtyEight, The Markup, the Washington Post, the Times, Hindustan Times, and Agence France-Presse (AP).
        </BlockParagraph>
        <BlockParagraph>
          I've written data-informed analysis and created data visualizations for the Washington Post and European Politics and Policy (EUROPP), published by the London School of Economics (LSE). I've published 10+ research articles in leading international journals.
        </BlockParagraph>
        <BlockParagraph>
          I have a PhD and MA in political science from Emory University (2019) with a specialization in quantitative methods. I also have a BBA in Business Honors from the Canfield Business Honors Program (CBHP), an innovative and accelerated MBA-style BBA program at the McCombs School of Business at The University of Texas at Austin, a top-10 business school. I have a professional certificate in machine learning from Stanford and DeepLearning.AI.
        </BlockParagraph>
      </Block>
    </Page>
  )
};

export default HomePage;
