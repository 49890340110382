import styled from "styled-components";

const Button = styled.button`
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.inverted ? "#ffffff" : "#27272D"};
  background: ${props => props.inverted ? "#27272D" : "#ffffff00"};
  border: ${props => props.inverted ? "1.5px solid #ffffff" : "1.5px solid #27272D"};
  border-radius: 50px;
  margin-right: 32px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 22px;
  padding-right: 22px;
  cursor: pointer;
  transition-duration: 0.2s;
  &:hover {
    color: ${props => props.inverted ? "#27272D" : "#ffffff"};
    background: ${props => props.inverted ? "#ffffff" : "#27272D"};
  }
`;

export default Button;
