import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  @media (min-width: 900px) {
    flex-direction: row;
    width: 650px;
  }
  @media (min-width: 1200px) {
    flex-direction: row;
    width: 900px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.02);
  width: 100%;
  max-width: 450px;
  height: 300px;
  margin-bottom: 24px;
  margin-right: 32px;
  @media (min-width: 900px) {
    min-width: 300px;
    max-width: 300px;
    height: 250px;
    margin-bottom: 0px;
  }
  @media (min-width: 1200px) {
    min-width: 450px;
    max-width: 450px;
    height: 350px;
    margin-bottom: 0px;
  }
`;


const Image = styled.img`
  height: 100%;
  opacity: 0.3;
`;

const Date = styled.div`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 24px;
  color: #27272D;
  opacity: 0.3;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: #27272D;
  margin-bottom: 24px;
  transition: color 0.3s;
  ${Card}:hover & {
    color: #57C1F6;
  }
  @media (min-width: 900px) {
    font-size: 32px;
  }
  @media (min-width: 1200px) {
    font-size: 40px;
  }
`;

const Author = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: #27272D;
`;

const BlogCard = (props) => {

  const { data, light } = props;

  return (
    <Card to={data.link}>
      <ImageWrapper>
        <Image src="/logo.svg" />
      </ImageWrapper>
      <ContentWrapper>
        <Date>
          {data.date}
        </Date>
        <Title>
          {data.title}
        </Title>
        <Author>
          By {data.author}
        </Author>
      </ContentWrapper>
    </Card>
  )
}

export default BlogCard;
