import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import { Block, BannerBlock, BlockTitle, BlockParagraph, BlockButtonWrapper } from "./block-components";
import Page from "./page.js";
import Button from "./button";
import Plot from "./plot";

const PortfolioPage = () => {
  return (
    <Page>
      <BannerBlock text={<div>Data<br />Science<br />Portfolio</div>} />
      {/* <Block theme="light">
        <BlockTitle>
          ML Projects
        </BlockTitle>
        <BlockParagraph>
          Here are some of the data science and machine learning (ML) projects I've been working on.
        </BlockParagraph>
        <Plot
          title="Partisanship.org"
          caption="This map shows the current status of European Union (EU) enlargement. It shows whether a country is a member state, a former member state, a candidate, country, a potential candidate country, a new applicant, or a former applicant. I made this map for an article I wrote in the Washington Post about Ukraine's application to join the EU."
          file="/plots/partisanship-1.png"
        />
        <Plot
          title="Partisanship.org"
          caption="This map shows the current status of European Union (EU) enlargement. It shows whether a country is a member state, a former member state, a candidate, country, a potential candidate country, a new applicant, or a former applicant. I made this map for an article I wrote in the Washington Post about Ukraine's application to join the EU."
          file="/plots/partisanship-2.png"
        />
        <Plot
          title="Partisanship.org"
          caption="This map shows the current status of European Union (EU) enlargement. It shows whether a country is a member state, a former member state, a candidate, country, a potential candidate country, a new applicant, or a former applicant. I made this map for an article I wrote in the Washington Post about Ukraine's application to join the EU."
          file="/plots/partisanship-3.png"
        />
      </Block> */}
      <Block theme="light">
        <BlockTitle>
          Data Viz
        </BlockTitle>
        <BlockParagraph>
          These are some of my favorite data visualizations I've done for projects. If you want to use data visualization to help your organization better communicate with customers or stakeholders, get in touch, and let's talk about how I can help.
        </BlockParagraph>
        <BlockButtonWrapper>
          <Button as={Link} to="/solutions#data-viz">
            Learn more.
          </Button>
        </BlockButtonWrapper>
        <Plot
          title="Map"
          caption="This map shows the current status of European Union (EU) enlargement. It shows whether a country is a member state, a former member state, a candidate country, a potential candidate country, a new applicant, or a former applicant. I made this map for an article I wrote in the Washington Post about Ukraine's application to join the EU."
          file="/plots/WaPo-map.png"
        />
        <Plot
          title="Timeline"
          caption="This timeline shows the progression of European Union (EU) enlargement. It shows the years that a country was a member state, a former member state, an applicant, and/or a candidate country. It also shows whether a country's application has been frozen or rejected. I created this map for an article I wrote in the Washington Post about Ukraine's application to join the EU."
          file="/plots/WaPo-timeline.png"
        />
        <Plot
          title="Hierarchical edge bundling graph"
          caption="This hierarchical edge bundling graph shows which countries have faced each other in the knockout stages of the FIFA World Cup (1990-2018). The points around the circle indicate the countries that have qualified for the knockout stages at least once since 1990. The size of the point indicates how many knockout matches the country has paritcipated in. An edge between two points indicates that those two countries have faced each other in at least one knockout match. The data comes from my World Cup database, which is available on GitHub."
          file="/plots/WC-hierarchical-edge-bundling-graph.png"
        />
        <Plot
          title="Tile plot"
          caption="This tile plot shows the result of every match at the FIFA World Cup (1930-2018) by tournament, confederation, and country. For each match, it shows whether the team won, lost, or drew. The data comes from my World Cup database, which is available on GitHub."
          file="/plots/WC-tile-plot.png"
        />
        <Plot
          title="Circular bar plot"
          caption="This circular bar plot shows the number of penalties attempted by each team that has participated in a penalty shootout at the FIFA World Cup (1930-2022). It also shows the proportion of penalty attempts that each team missed. The data comes from my World Cup database, which is available on GitHub."
          file="/plots/WC-circular-bar-plot.png"
        />
        <Plot
          title="Beeswarm plot"
          caption="This beeswarm plot shows the number of Star Wars sets LEGO has produced by year and Star Wars era. I made this plot for an article that I wrote for Brickset for Star Wars Day about how LEGO depicts conflict in Star Wars sets."
          file="/plots/LEGO-beeswarm-plot.png"
        />
        <Plot
          title="Circle packing graph"
          caption="This circle packing graph shows the number of unique Minifigures LEGO has produced for all Star Wars characters that have appeared in at least one LEGO set by Star Wars era and faction (the main protagonist faction and main antagonist faction in each era). I made this plot for an article that I wrote for Brickset for Star Wars Day about how LEGO depicts conflict in Star Wars sets."
          file="/plots/LEGO-circle-packing-plot.png"
        />
        <Plot
          title="Hierarchical edge bundling graph"
          caption="This hierarchical edge bundling plot shows common Star Wars characters and whether they appear in at least one LEGO Star Wars set together. The points around the circle indicate characters that have frequently appeared in LEGO Star Wars sets. The size of the point indicates how many sets the character has appeared in. The color of the point indicates whether the character is portrayed as a protagonist or an antagonist. An edge between two points indicates that those two characters have appeared in at least one set together. I made this plot for an article that I wrote for Brickset for Star Wars Day about how LEGO depicts conflict in Star Wars sets."
          file="/plots/LEGO-hierarchical-edge-bundling-graph.png"
        />
        <Plot
          title="Network graph"
          caption="This network graph shows characters from the Age of Rebellion era in Star Wars and whether they appear in at least one LEGO Star Wars set together. Each character is a node, and the color of each node indicates whether the character is a protagonist, an antagonist, or a neutral character. An edge between two points indicates that those two characters have appeared in at least one LEGO set together. I made this plot for an article that I wrote for Brickset for Star Wars Day about how LEGO depicts conflict in Star Wars sets."
          file="/plots/LEGO-network-graph.png"
        />
        <Plot
          title="Stream plot"
          caption="This stream plot shows the proportion LEGO Star Wars sets produced each year that contain only protaginsts, only antagonists, or both protagonists and antagonists. I made this plot for an article that I wrote for Brickset for Star Wars Day about how LEGO depicts conflict in Star Wars sets."
          file="/plots/LEGO-stream-plot.png"
        />
      </Block>
    </Page>
  )
};

export default PortfolioPage;
