import React, { useEffect } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import styled from "styled-components";
import Chip from "./chip";
import BlogCard from "./blog-card";
import Page from "./page";

const TitleWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 48px;
  @media (min-width: 900px) {
    padding-left: 100px;
    padding-right: 100px;
  }
`;

const Date = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: #27272D;
  margin-bottom: 48px;
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 200;
  line-height: 1.3;
  color: #27272D;
  margin-bottom: 48px;
  @media (min-width: 900px) {
    font-size: 56px;
  }
  @media (min-width: 1200px) {
    font-size: 72px;
  }
`;

const Author = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #27272D;
  margin-bottom: 12px;
`;

const AuthorTitle = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: #27272D;
`;

const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
  background: rgba(0, 0, 0, 0.02);
  margin-bottom: 48px;
`;

const BannerImage = styled.img`
  height: 100%;
  max-width: 100%;
  opacity: 0.3;
`;

const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
  @media (min-width: 900px) {
    width: 500px;
    margin-left: 200px;
    margin-right: 0px;
  }
  @media (min-width: 1200px) {
    width: 700px;
  }
`;

const Summary = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 1.5;
`;

const Heading = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  margin-top: 48px;
  margin-bottom: 24px;
  color: #27272D;
`;

const Paragraph = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 24px;
  color: #27272D;
`;

const Image = styled.div`
  width: 100%;
  height: 300px;
  background: rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
  @media (min-width: 900px) {
    height: 500px;
  }
`;

const ChipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 48px;
`;

const RecommendationText = styled.div`
  font-size: 32px;
  font-weight: 200;
  padding-bottom: 32px;
  color: #27272D;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @media (min-width: 900px) {
    font-size: 48px;
  }
  @media (min-width: 1200px) {
    font-size: 56px;
  }
`;

const CardWrapper = styled.div`
  margin-top: 96px;
  margin-bottom: 96px;
  margin-left: 16px;
  margin-right: 16px;
  @media (min-width: 900px) {
    margin-left: 96px;
    margin-right: 48px;
  }
`;

const Code = styled(SyntaxHighlighter)`
  line-height: 1.4;
  font-size: 16px;
  // background: rgba(0, 0, 0, 0.02) !important;
  background: #2F343F !important;
  margin-bottom: 24px;
  border-radius: 8px;
  padding: 24px !important;
  max-width: calc(100vw - 48px - 32px);
`;

const BlogPost = (props) => {

  const { data, cardData } = props;

  return (
    <Page>
      <TitleWrapper>
        <Date>
          {data.date}
        </Date>
        <Title>
          {data.title}
        </Title>
        <Author>
          {data.author}
        </Author>
        <AuthorTitle>
          {data.author_title}
        </AuthorTitle>
      </TitleWrapper>
      <BannerWrapper>
        <BannerImage src={"/logo.svg"} />
      </BannerWrapper>
      <Content>
        <ChipWrapper>
          {data.tags.map((item, i) => {
            return (
              <Chip key={i}>
                {item}
              </Chip>
            )
          })}
        </ChipWrapper>
        <Summary>
          {data.summary}
        </Summary>
        {data.content.map((item, i) => {
          if (item.type === "heading") {
            return (
              <Heading key={i}>
                {item.text}
              </Heading>
            )
          } else if (item.type === "paragraph") {
            return (
              <Paragraph key={i}>
                {item.text}
              </Paragraph>
            )
          } else if (item.type === "image") {
            return (
              <Image key={i} src={item.file} />
            )
          } else if (item.type === "code") {
            return (
              <Code language="r" style={atomOneDark}>
                {item.text}
              </Code>
            )
          }
        })}
      </Content>
      <CardWrapper>
        <RecommendationText>
          Recommended Posts
        </RecommendationText>
        {cardData.map((item, i) => {
          if (data.id !== item.id) {
            return (
              <BlogCard key={i} data={item} light={i % 2 === 0 ? true : false} />
            )
          }
        })}
      </CardWrapper>
    </Page>
  )
}

export default BlogPost;
