import styled from "styled-components";

const FooterWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #27272D;
  padding-top: 100px;
  padding-left: 0px;
  @media (min-width: 900px) {
    padding-left: 125px;
  }
`;

const FooterLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
`;

const FooterLogo = styled.img`
  height: 120px;
  @media (min-width: 900px) {
    height: 240px;
  }
`;

const FooterWordMark = styled.img`
  height: 60px;
  padding-top: 8px;
  @media (min-width: 900px) {
    height: 120px;
    padding-top: 16px;
  }
`;

const SocialMediaButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

const SocialMediaButton = styled.img`
  height: 18px;
  width: 18px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 100px;
  cursor: pointer;
  opacity: 0.4;
  transition-duration: 0.3s;
  &:hover {
    opacity: 1;
  }
  @media (min-width: 900px) {
    height: 24px;
    width: 24px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Copyright = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0.2;
  font-size: 10pt;
  font-weight: 300;
  margin-bottom: 32px;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterLogoWrapper>
        <FooterLogo src="/logo.svg" />
        <FooterWordMark src="/word-mark-white.svg" />
      </FooterLogoWrapper>
      {/* <SocialMediaButtons>
          <SocialMediaButton src="/social-logos/github-logo.svg" />
          <SocialMediaButton src="/social-logos/linkedin-logo.svg" />
          <SocialMediaButton src="/social-logos/x-logo.svg" />
          <SocialMediaButton src="/social-logos/threads-logo.svg" />
          <SocialMediaButton src="/social-logos/instagram-logo.svg" />
        </SocialMediaButtons> */}
      <Copyright>
        © 2023 J. C. Fjelstul Consulting LLC
      </Copyright>
    </FooterWrapper>
  );
}

export default Footer;
