import styled from "styled-components";

const Page = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 0px;
  width: 100%;
  @media (min-width: 900px) {
    padding-left: 100px;
  }
`;

export default Page;
