import styled from "styled-components";
import Button from "./button.js";

const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(72px - 24px);
  margin-bottom: 72px;
  margin-left: 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  @media (min-width: 900px) {
    margin-left: 96px;
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 48px;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  @media (min-width: 900px) {
    flex-direction: row;
    margin-right: 0px;
    font-size: 18px;
    width: 500px;
    padding-top: 72px;
    padding-bottom: 72px;
  }
  @media (min-width: 1200px) {
    width: 700px;
  }
`;

const Number = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 96px;
  font-weight: 200;
  color: #57C1F6;
  height: 100px;
  width: 100px;
  margin-top: -12px;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ImageWrapper = styled.div`
  position: relative;
  min-height: 96px;
  min-width: 96px;
  margin-right: 32px;
  margin-bottom: 16px;
`;

const Image = styled.img`
  height: 96px;
  width: 96px;
`;

const ImageOffset = styled.img`
  position: absolute;
  top: 8px;
  left: 8px;
  height: 96px;
  width: 96px;
  opacity: 0.2;
`;

const Title = styled.div`
  font-size: 22px;s
  line-height: 1.3;
  font-weight: 600;
  color: #27272C;
  margin-bottom: 16px;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: #27272C;
`;

const ButtonWrapper = styled.div`
  margin-top: 32px;
`;

const Feature = (props) => {

  const { title, text, file, buttonLabel, onClick } = props;

  var button = null;
  if (buttonLabel !== undefined) {
    button =
      <ButtonWrapper>
        <Button onClick={() => onClick}>
          {buttonLabel}
        </Button>
      </ButtonWrapper>
  }

  return (
    <Wrapper>
      <ImageWrapper>
        <Image src={file} />
        <ImageOffset src={file} />
      </ImageWrapper>
      <InnerWrapper>
        <Title>
          {title}
        </Title>
        <Text>
          {text}
        </Text>
        {button}
      </InnerWrapper>
    </Wrapper>
  );
};

const NumberedFeature = (props) => {
  const { number, title, text } = props;
  return (
    <Wrapper>
      <ImageWrapper>
        <Number>
          {number}.
        </Number>
      </ImageWrapper>
      <InnerWrapper>
        <Title>
          {title}
        </Title>
        <Text>
          {text}
        </Text>
      </InnerWrapper>
    </Wrapper>
  );
};

export { Feature, NumberedFeature, FeatureList };
